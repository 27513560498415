import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [
        {
            title: "Roze",
            image: "https://i.imgur.com/rfq26BHb.png",
            native: "Āzija",
            description: "Šoreiz ir atlasītas 2 šķirnes Harmony Peach  un Rozes Minion. Vai zināji, ka pasaulē ir vairāk par 25 000 rožu šķirņu?",
        },
        {
            title: "Roze Garden Persuation",
            image: "https://i.imgur.com/0TEq4VCb.jpg",
            native: "",
            description: "Šāda tipa rozes, vēl mēdz saukt par Angļu rozēm, vai nosaukumā tām ir vārds “Garden” . Ieskaties dziļāk, tām ir skaists un pildīts vidus. Ja šāda veida rozes iegādājies veikalā, tad vadies pēc principa, jo garāks ir rozes kāts, jo lielāks zieds izplauks un, jo ilgāk tā nenovecos vāzē.",
        },
        {
            title: "Magones",
            image: "https://i.imgur.com/OY8b2iob.jpg",
            native: "",
            description: "Šie ziedi ir ļoti trausli, tāpēc tie tiek transportēti vēl pumpuros. Interesanti fakts, ka mēs pasūtījām 3 krāsu ziedus - dzeltenā, oranžā un krēmīgi baltā, kamēr pumpuri vēl ir ciet, nav iespējams noteikt krāsu, tāpēc arī jūsu vāzēs izplauks pārsteigums. Ziedu ilgums vāzē salīdzinoši īss 3-5 dienas, bet kas par skaistumu."
        },
        
        {
            title: "Neapoles ķiploks Allium Neopolitanum",
            image: "https://i.imgur.com/b4iq96Eb.jpg",
            native: "",
            description: 'Sniegbalts čemuru zieds, kas nebūt nesmaržo pēc ķiploka. Zieda garums var izaugt līdz pat 30cm un zieda lielums līdz pat 10 cm. Ilgums vāzē 6-10 dienas.',
        },
        {
            title: "Dadzis Eryngium Supernova",
            image: "https://i.imgur.com/K3kQtAWb.jpg",
            native: "",
            description: 'Vāzē tas izziedēs tumši zilā krāsā, un ar savu izskatu, tie brīnišķīgi atsvaidzina jebkuru kompozīciju vai ziedu pušķi. Tas arī ilgi nenovecos vāzē. Varat pat mēģināt to izžāvēt un izmantot sausziedu kompozīcijām.',
        },
        {
            title: "Protea Nutan Soleil",
            image: "https://i.imgur.com/0OfEBaXb.jpg",
            native: "",
            description: "Ir vieni no vecākajiem augiem pasaulē. Tiek uzskatīts, ka tie ir auguši 300miljons gadus atpakaļ. Kopumā ir ap 1400 dažādu šķirņu. Šie augi simpolizē dažādību un drosmi, iespējams tāpēc, ka spējuši pielāgoties un izdzīvot tik ilgi.",
        },
        {
            title: "Eikalipts",
            image: "https://i.imgur.com/v4Ldp1Pb.png",
            native: "Āzija",
            description: "Mūžžaļi augi, ar labi pazīstamu uzmundrinošo eikalipta smaržu. Šoreiz izvēlētās sugas ir Parvifolia and Populus, kas atšķiras ar lapu formu un lielumu. Vai zināji, ka eikalipti saglabā lielāko daļu smaržu izdalošās eļļas lapās? tādēļ tieši no lapām iegūst eikalipta ēterisko eļļu.",
        }
    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">


                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/b0ba0b03-442d-42ce-9c1b-3782d879e673/playlist.m3u8"
                        captions="/titles/006.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Iepazīšanās ar Boho stilu</h2>

                            <p>Katru nedēļu, izvēloties ziedus kompozīcijai, balansējam ar vēlmi pieturēties pie kā klasiskāka, kā iepriekšējā nedēļā, vai izmantot ko eksotiskāku, lai pārsteigtu un iepazīstinātu ar netradicionāliem ziediem.</p>
                                
                            <p>Šoreiz vēlējāmies Tev dot iespēju izlemt savu kompozīcijas stilu, sagatavojot ierastākas, bet tajā pašā laikā, ļoti iespaidīgas, vairāku veidu rozes, tostarp lielās dārza rozes, un papildinot tās ar ko interesantu, piemēram, ķiploka ziediem, dekoratīvajiem dadžiem, niedrēm.</p>

                            <p>Ak jā, un diviem īpašiem ziediem - oranžiem Protea Nutan Soleil un sarkanām magonēm. </p>

                            <p>Mums tas atgādina Boho stilu, kas apvieno dabisko, dabā esošo, eksotisko un grezno vienotā un dabīgā kompozīcijā, ko vari pielāgot pēc saviem ieskatiem. Piemēram, neizmantojot niedres, vai noliekot oranžo Protea Nutan Soleil savā vāzē, kā akcentu citā telpā.</p>

                            <p>Piemērotākā vāze būs ar platāku kaklu. Kārtojot kompozīciju, vari ievērot trīs līmeņus</p>
                            <ul>
                                <li>Apakšējais - zaļumi, no kuriem daži var būt garāki, lai atbalstītu magones</li>
                                <li>Vidējais - rozes, dadži un ķiploka ziedi</li>
                                <li>Augšējais - magones un, ja vēlies, niedres</li>
                            </ul>

                            <p>Ņem vērā:</p>

                            <ul>
                                <li>Magones vēl var būt pumpuros, kuru katru mirkli tās atvērsies.</li>
                                <li>Ķiploku ziedi var būt sapinušies. Tos var viegli atdalīt, turot aiz stublājiem, un maigi pakratot. Skaties to video.</li>
                            </ul>

                            <p>Sāksim darbu!</p>

                            <ol>
                                <li>Vāzē lej ūdeni istabas temperatūrā </li>
                                <li>Pievieno ziedu barību, 1 paciņa uz 0,5l ūdens, samaisi</li>
                                <li>Obligāti, visiem ziedu stublājiem nogriez 2cm no apakšas, lai tie spētu uzsūkt ūdeni un ilgāk paliktu svaigi</li>
                                <li>Sākam ar zaļumiem, ko izkārtojam pa vāzes perimetru, atstājot vienu garāku zariņu beigām</li>
                                <li>Dadža zariņu centies ielikt vertikāli, kompozīcijas centrā</li>
                                <li>Centrā ieliec oranžo Protea Nutan Soleil</li>
                                <li>Kārto rozes viscaur kompozīcijai, tās liekot krusteniski, cauri zaļumu veidotajam režģim vāzē. Tās var būt vajadzīgs saīsināt, ja izmanto zemāku vāzi</li>
                                <li>Piešķir mirdzumu, ieliekot ķiploku ziedus starp rozēm</li>
                                <li>Ja vēlies, izmanto niedres, lai aizpildītu kompozīcijas tukšumus un piešķirtu Boho stilu</li>
                                <li>Pataupītais zaļumu zars tagad var noderēt kompozīcijas aizmugurē</li>
                                <li>Visbeidzot centies ievietot magones, tās ir nokarenas, tāpēc neuztraucies ja tas sagādā problēmas. Es tās ieliku kompozīcijas malā, lai tās nokarājas pāri vāzes malām. Tu vari tās arī atbalstīt zaļajos zariņos.</li>
                                {/* <li>Pirmais triks, ziedu stublājiem jābūt vienā garumā, lai spētu izveidot apaļu kompozīciju, to garums ir atkarīgs no Tavas vāzes augstuma</li> */}
                                {/* <li>Sāc ar zaļi baltajiem zariņiem (Pittosporum), kur tos pavisam vienkārši izkārto vāzē - šie zariņi ir kā skelets kompozīcijai, kas dos apjomu un noturēs formu </li> */}
                                {/* <li>Tālāk liec stap zariem Viburnum Roseum jeb sniega bumbas. Triks ir izmantot zaļi baltos zariņus, kā atbalstus, lai uz tiem uzliktu smagos ziedus, kā arī tie ļauj simetriski izkārtot ziedu bumbas kompozīcijā. Grūtākais ir padarīts</li> */}
                                {/* <li>Izkārto vāzē pildītās, lielās Rozes, Krūmrozes un smaržīgās Gerberas </li> */}
                                {/* <li>Visbeidzot kā pēdējās liec vāzē Anemones, tie ir ļoti trausli ziedi, tāpēc tās tiks piegādās vēl pumpuros, bet atrodoties istabas temperatūrā tās pavisam neilgā laikā atvērsies</li> */}
                            </ol>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Instagram!</p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/bIfMbtXh.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p>Īpaši labi ziedi stāvēs, ja ūdeni tiem mainīsi reizi 2 dienās. Kā to pareizi darīt? </p>

                            <ol>
                                <li>Noskalo ziedu kātus</li>
                                <li>Izmazgā vāzi</li>
                                <li>Iepildi istabas temperatūras ūdeni, tajā izšķīdinot ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja  koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
